import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _762fa299 = () => interopDefault(import('../pages/authorization/index.vue' /* webpackChunkName: "pages/authorization/index" */))
const _17260458 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _db391c0e = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _21977e08 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "" */))
const _9ea7c030 = () => interopDefault(import('../pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _4157e789 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _439c41af = () => interopDefault(import('../pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _fe0b50e2 = () => interopDefault(import('../pages/request-password/index.vue' /* webpackChunkName: "pages/request-password/index" */))
const _13c15fc4 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _20d1cf5c = () => interopDefault(import('../pages/success-payment/index.vue' /* webpackChunkName: "pages/success-payment/index" */))
const _5d5b22b4 = () => interopDefault(import('../pages/countries/ind222ex__.vue' /* webpackChunkName: "pages/countries/ind222ex__" */))
const _1ce9366e = () => interopDefault(import('../pages/marketplace/account/index.vue' /* webpackChunkName: "pages/marketplace/account/index" */))
const _b418b51a = () => interopDefault(import('../pages/marketplace/registration/index.vue' /* webpackChunkName: "pages/marketplace/registration/index" */))
const _a5a52d06 = () => interopDefault(import('../pages/partner/activations-history/index.vue' /* webpackChunkName: "pages/partner/activations-history/index" */))
const _8fc20a54 = () => interopDefault(import('../pages/partner/price-regulator/index.vue' /* webpackChunkName: "pages/partner/price-regulator/index" */))
const _3bd9e623 = () => interopDefault(import('../pages/partner/sim-card/index.vue' /* webpackChunkName: "pages/partner/sim-card/index" */))
const _3746b469 = () => interopDefault(import('../pages/partner/statistic/index.vue' /* webpackChunkName: "pages/partner/statistic/index" */))
const _4dcc7513 = () => interopDefault(import('../pages/partner/withdrawal-funds/index.vue' /* webpackChunkName: "pages/partner/withdrawal-funds/index" */))
const _731b4e64 = () => interopDefault(import('../pages/profile/history/index.vue' /* webpackChunkName: "pages/profile/history/index" */))
const _791d95f7 = () => interopDefault(import('../pages/profile/loyalty-program/index.vue' /* webpackChunkName: "pages/profile/loyalty-program/index" */))
const _794df93c = () => interopDefault(import('../pages/profile/pay/index.vue' /* webpackChunkName: "pages/profile/pay/index" */))
const _ae3d49a0 = () => interopDefault(import('../pages/profile/referral-program/index.vue' /* webpackChunkName: "pages/profile/referral-program/index" */))
const _71b3b320 = () => interopDefault(import('../pages/profile/sale-agreement/index.vue' /* webpackChunkName: "pages/profile/sale-agreement/index" */))
const _d506bd82 = () => interopDefault(import('../pages/profile/settings/index.vue' /* webpackChunkName: "pages/profile/settings/index" */))
const _db082c02 = () => interopDefault(import('../pages/profile/statistics/index.vue' /* webpackChunkName: "pages/profile/statistics/index" */))
const _372b9249 = () => interopDefault(import('../pages/profile/tariffs/index.vue' /* webpackChunkName: "pages/profile/tariffs/index" */))
const _ff931aea = () => interopDefault(import('../pages/signup/confirm-email.vue' /* webpackChunkName: "pages/signup/confirm-email" */))
const _6de39c42 = () => interopDefault(import('../pages/marketplace/account/adding-product/index.vue' /* webpackChunkName: "pages/marketplace/account/adding-product/index" */))
const _3baa3354 = () => interopDefault(import('../pages/marketplace/account/balance/index.vue' /* webpackChunkName: "pages/marketplace/account/balance/index" */))
const _d3b0c4bc = () => interopDefault(import('../pages/marketplace/registration/originalIndex.vue' /* webpackChunkName: "pages/marketplace/registration/originalIndex" */))
const _156f6310 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _c2894306 = () => interopDefault(import('../pages/bot-link/_link.vue' /* webpackChunkName: "pages/bot-link/_link" */))
const _7513ffdc = () => interopDefault(import('../pages/confirm/_token.vue' /* webpackChunkName: "pages/confirm/_token" */))
const _43e3da86 = () => interopDefault(import('../pages/catalog/_.vue' /* webpackChunkName: "pages/catalog/_" */))
const _3f5867a1 = () => interopDefault(import('../pages/countries/_.vue' /* webpackChunkName: "pages/countries/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/authorization",
    component: _762fa299,
    pathToRegexpOptions: {"strict":true},
    name: "authorization___en"
  }, {
    path: "/blog",
    component: _17260458,
    pathToRegexpOptions: {"strict":true},
    name: "blog___en"
  }, {
    path: "/catalog",
    component: _db391c0e,
    pathToRegexpOptions: {"strict":true},
    name: "catalog___en"
  }, {
    path: "/cn",
    components: {
      default: _21977e08
    },
    name: "index___cn"
  }, {
    path: "/partner",
    component: _9ea7c030,
    pathToRegexpOptions: {"strict":true},
    name: "partner___en"
  }, {
    path: "/profile",
    component: _4157e789,
    pathToRegexpOptions: {"strict":true},
    name: "profile___en"
  }, {
    path: "/registration",
    component: _439c41af,
    pathToRegexpOptions: {"strict":true},
    name: "registration___en"
  }, {
    path: "/request-password",
    component: _fe0b50e2,
    pathToRegexpOptions: {"strict":true},
    name: "request-password___en"
  }, {
    path: "/ru",
    components: {
      default: _21977e08
    },
    name: "index___ru"
  }, {
    path: "/search",
    component: _13c15fc4,
    pathToRegexpOptions: {"strict":true},
    name: "search___en"
  }, {
    path: "/success-payment",
    component: _20d1cf5c,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment___en"
  }, {
    path: "/tr",
    components: {
      default: _21977e08
    },
    name: "index___tr"
  }, {
    path: "/cn/authorization",
    component: _762fa299,
    pathToRegexpOptions: {"strict":true},
    name: "authorization___cn"
  }, {
    path: "/cn/blog",
    component: _17260458,
    pathToRegexpOptions: {"strict":true},
    name: "blog___cn"
  }, {
    path: "/cn/catalog",
    component: _db391c0e,
    pathToRegexpOptions: {"strict":true},
    name: "catalog___cn"
  }, {
    path: "/cn/partner",
    component: _9ea7c030,
    pathToRegexpOptions: {"strict":true},
    name: "partner___cn"
  }, {
    path: "/cn/profile",
    component: _4157e789,
    pathToRegexpOptions: {"strict":true},
    name: "profile___cn"
  }, {
    path: "/cn/registration",
    component: _439c41af,
    pathToRegexpOptions: {"strict":true},
    name: "registration___cn"
  }, {
    path: "/cn/request-password",
    component: _fe0b50e2,
    pathToRegexpOptions: {"strict":true},
    name: "request-password___cn"
  }, {
    path: "/cn/search",
    component: _13c15fc4,
    pathToRegexpOptions: {"strict":true},
    name: "search___cn"
  }, {
    path: "/cn/success-payment",
    component: _20d1cf5c,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment___cn"
  }, {
    path: "/countries/ind222ex__",
    component: _5d5b22b4,
    pathToRegexpOptions: {"strict":true},
    name: "countries-ind222ex_____en"
  }, {
    path: "/marketplace/account",
    component: _1ce9366e,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account___en"
  }, {
    path: "/marketplace/registration",
    component: _b418b51a,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration___en"
  }, {
    path: "/partner/activations-history",
    component: _a5a52d06,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history___en"
  }, {
    path: "/partner/price-regulator",
    component: _8fc20a54,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator___en"
  }, {
    path: "/partner/sim-card",
    component: _3bd9e623,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card___en"
  }, {
    path: "/partner/statistic",
    component: _3746b469,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic___en"
  }, {
    path: "/partner/withdrawal-funds",
    component: _4dcc7513,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds___en"
  }, {
    path: "/profile/history",
    component: _731b4e64,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history___en"
  }, {
    path: "/profile/loyalty-program",
    component: _791d95f7,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program___en"
  }, {
    path: "/profile/pay",
    component: _794df93c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay___en"
  }, {
    path: "/profile/referral-program",
    component: _ae3d49a0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program___en"
  }, {
    path: "/profile/sale-agreement",
    component: _71b3b320,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement___en"
  }, {
    path: "/profile/settings",
    component: _d506bd82,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___en"
  }, {
    path: "/profile/statistics",
    component: _db082c02,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics___en"
  }, {
    path: "/profile/tariffs",
    component: _372b9249,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs___en"
  }, {
    path: "/ru/authorization",
    component: _762fa299,
    pathToRegexpOptions: {"strict":true},
    name: "authorization___ru"
  }, {
    path: "/ru/blog",
    component: _17260458,
    pathToRegexpOptions: {"strict":true},
    name: "blog___ru"
  }, {
    path: "/ru/catalog",
    component: _db391c0e,
    pathToRegexpOptions: {"strict":true},
    name: "catalog___ru"
  }, {
    path: "/ru/partner",
    component: _9ea7c030,
    pathToRegexpOptions: {"strict":true},
    name: "partner___ru"
  }, {
    path: "/ru/profile",
    component: _4157e789,
    pathToRegexpOptions: {"strict":true},
    name: "profile___ru"
  }, {
    path: "/ru/registration",
    component: _439c41af,
    pathToRegexpOptions: {"strict":true},
    name: "registration___ru"
  }, {
    path: "/ru/request-password",
    component: _fe0b50e2,
    pathToRegexpOptions: {"strict":true},
    name: "request-password___ru"
  }, {
    path: "/ru/search",
    component: _13c15fc4,
    pathToRegexpOptions: {"strict":true},
    name: "search___ru"
  }, {
    path: "/ru/success-payment",
    component: _20d1cf5c,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment___ru"
  }, {
    path: "/signup/confirm-email",
    component: _ff931aea,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email___en"
  }, {
    path: "/tr/authorization",
    component: _762fa299,
    pathToRegexpOptions: {"strict":true},
    name: "authorization___tr"
  }, {
    path: "/tr/blog",
    component: _17260458,
    pathToRegexpOptions: {"strict":true},
    name: "blog___tr"
  }, {
    path: "/tr/catalog",
    component: _db391c0e,
    pathToRegexpOptions: {"strict":true},
    name: "catalog___tr"
  }, {
    path: "/tr/partner",
    component: _9ea7c030,
    pathToRegexpOptions: {"strict":true},
    name: "partner___tr"
  }, {
    path: "/tr/profile",
    component: _4157e789,
    pathToRegexpOptions: {"strict":true},
    name: "profile___tr"
  }, {
    path: "/tr/registration",
    component: _439c41af,
    pathToRegexpOptions: {"strict":true},
    name: "registration___tr"
  }, {
    path: "/tr/request-password",
    component: _fe0b50e2,
    pathToRegexpOptions: {"strict":true},
    name: "request-password___tr"
  }, {
    path: "/tr/search",
    component: _13c15fc4,
    pathToRegexpOptions: {"strict":true},
    name: "search___tr"
  }, {
    path: "/tr/success-payment",
    component: _20d1cf5c,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment___tr"
  }, {
    path: "/cn/countries/ind222ex__",
    component: _5d5b22b4,
    pathToRegexpOptions: {"strict":true},
    name: "countries-ind222ex_____cn"
  }, {
    path: "/cn/marketplace/account",
    component: _1ce9366e,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account___cn"
  }, {
    path: "/cn/marketplace/registration",
    component: _b418b51a,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration___cn"
  }, {
    path: "/cn/partner/activations-history",
    component: _a5a52d06,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history___cn"
  }, {
    path: "/cn/partner/price-regulator",
    component: _8fc20a54,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator___cn"
  }, {
    path: "/cn/partner/sim-card",
    component: _3bd9e623,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card___cn"
  }, {
    path: "/cn/partner/statistic",
    component: _3746b469,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic___cn"
  }, {
    path: "/cn/partner/withdrawal-funds",
    component: _4dcc7513,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds___cn"
  }, {
    path: "/cn/profile/history",
    component: _731b4e64,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history___cn"
  }, {
    path: "/cn/profile/loyalty-program",
    component: _791d95f7,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program___cn"
  }, {
    path: "/cn/profile/pay",
    component: _794df93c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay___cn"
  }, {
    path: "/cn/profile/referral-program",
    component: _ae3d49a0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program___cn"
  }, {
    path: "/cn/profile/sale-agreement",
    component: _71b3b320,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement___cn"
  }, {
    path: "/cn/profile/settings",
    component: _d506bd82,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___cn"
  }, {
    path: "/cn/profile/statistics",
    component: _db082c02,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics___cn"
  }, {
    path: "/cn/profile/tariffs",
    component: _372b9249,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs___cn"
  }, {
    path: "/cn/signup/confirm-email",
    component: _ff931aea,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email___cn"
  }, {
    path: "/marketplace/account/adding-product",
    component: _6de39c42,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-adding-product___en"
  }, {
    path: "/marketplace/account/balance",
    component: _3baa3354,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-balance___en"
  }, {
    path: "/marketplace/registration/originalIndex",
    component: _d3b0c4bc,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration-originalIndex___en"
  }, {
    path: "/ru/countries/ind222ex__",
    component: _5d5b22b4,
    pathToRegexpOptions: {"strict":true},
    name: "countries-ind222ex_____ru"
  }, {
    path: "/ru/marketplace/account",
    component: _1ce9366e,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account___ru"
  }, {
    path: "/ru/marketplace/registration",
    component: _b418b51a,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration___ru"
  }, {
    path: "/ru/partner/activations-history",
    component: _a5a52d06,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history___ru"
  }, {
    path: "/ru/partner/price-regulator",
    component: _8fc20a54,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator___ru"
  }, {
    path: "/ru/partner/sim-card",
    component: _3bd9e623,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card___ru"
  }, {
    path: "/ru/partner/statistic",
    component: _3746b469,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic___ru"
  }, {
    path: "/ru/partner/withdrawal-funds",
    component: _4dcc7513,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds___ru"
  }, {
    path: "/ru/profile/history",
    component: _731b4e64,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history___ru"
  }, {
    path: "/ru/profile/loyalty-program",
    component: _791d95f7,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program___ru"
  }, {
    path: "/ru/profile/pay",
    component: _794df93c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay___ru"
  }, {
    path: "/ru/profile/referral-program",
    component: _ae3d49a0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program___ru"
  }, {
    path: "/ru/profile/sale-agreement",
    component: _71b3b320,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement___ru"
  }, {
    path: "/ru/profile/settings",
    component: _d506bd82,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___ru"
  }, {
    path: "/ru/profile/statistics",
    component: _db082c02,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics___ru"
  }, {
    path: "/ru/profile/tariffs",
    component: _372b9249,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs___ru"
  }, {
    path: "/ru/signup/confirm-email",
    component: _ff931aea,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email___ru"
  }, {
    path: "/tr/countries/ind222ex__",
    component: _5d5b22b4,
    pathToRegexpOptions: {"strict":true},
    name: "countries-ind222ex_____tr"
  }, {
    path: "/tr/marketplace/account",
    component: _1ce9366e,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account___tr"
  }, {
    path: "/tr/marketplace/registration",
    component: _b418b51a,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration___tr"
  }, {
    path: "/tr/partner/activations-history",
    component: _a5a52d06,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history___tr"
  }, {
    path: "/tr/partner/price-regulator",
    component: _8fc20a54,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator___tr"
  }, {
    path: "/tr/partner/sim-card",
    component: _3bd9e623,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card___tr"
  }, {
    path: "/tr/partner/statistic",
    component: _3746b469,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic___tr"
  }, {
    path: "/tr/partner/withdrawal-funds",
    component: _4dcc7513,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds___tr"
  }, {
    path: "/tr/profile/history",
    component: _731b4e64,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history___tr"
  }, {
    path: "/tr/profile/loyalty-program",
    component: _791d95f7,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program___tr"
  }, {
    path: "/tr/profile/pay",
    component: _794df93c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay___tr"
  }, {
    path: "/tr/profile/referral-program",
    component: _ae3d49a0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program___tr"
  }, {
    path: "/tr/profile/sale-agreement",
    component: _71b3b320,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement___tr"
  }, {
    path: "/tr/profile/settings",
    component: _d506bd82,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___tr"
  }, {
    path: "/tr/profile/statistics",
    component: _db082c02,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics___tr"
  }, {
    path: "/tr/profile/tariffs",
    component: _372b9249,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs___tr"
  }, {
    path: "/tr/signup/confirm-email",
    component: _ff931aea,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email___tr"
  }, {
    path: "/cn/marketplace/account/adding-product",
    component: _6de39c42,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-adding-product___cn"
  }, {
    path: "/cn/marketplace/account/balance",
    component: _3baa3354,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-balance___cn"
  }, {
    path: "/cn/marketplace/registration/originalIndex",
    component: _d3b0c4bc,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration-originalIndex___cn"
  }, {
    path: "/ru/marketplace/account/adding-product",
    component: _6de39c42,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-adding-product___ru"
  }, {
    path: "/ru/marketplace/account/balance",
    component: _3baa3354,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-balance___ru"
  }, {
    path: "/ru/marketplace/registration/originalIndex",
    component: _d3b0c4bc,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration-originalIndex___ru"
  }, {
    path: "/tr/marketplace/account/adding-product",
    component: _6de39c42,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-adding-product___tr"
  }, {
    path: "/tr/marketplace/account/balance",
    component: _3baa3354,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-balance___tr"
  }, {
    path: "/tr/marketplace/registration/originalIndex",
    component: _d3b0c4bc,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration-originalIndex___tr"
  }, {
    path: "/cn/blog/:slug",
    component: _156f6310,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___cn"
  }, {
    path: "/cn/bot-link/:link?",
    component: _c2894306,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link___cn"
  }, {
    path: "/cn/confirm/:token?",
    component: _7513ffdc,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token___cn"
  }, {
    path: "/ru/blog/:slug",
    component: _156f6310,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___ru"
  }, {
    path: "/ru/bot-link/:link?",
    component: _c2894306,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link___ru"
  }, {
    path: "/ru/confirm/:token?",
    component: _7513ffdc,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token___ru"
  }, {
    path: "/tr/blog/:slug",
    component: _156f6310,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___tr"
  }, {
    path: "/tr/bot-link/:link?",
    component: _c2894306,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link___tr"
  }, {
    path: "/tr/confirm/:token?",
    component: _7513ffdc,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token___tr"
  }, {
    path: "/tr/catalog/*",
    component: _43e3da86,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-all___tr"
  }, {
    path: "/cn/catalog/*",
    component: _43e3da86,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-all___cn"
  }, {
    path: "/ru/catalog/*",
    component: _43e3da86,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-all___ru"
  }, {
    path: "/tr/countries/*",
    component: _3f5867a1,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all___tr"
  }, {
    path: "/cn/countries/*",
    component: _3f5867a1,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all___cn"
  }, {
    path: "/ru/countries/*",
    component: _3f5867a1,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all___ru"
  }, {
    path: "/blog/:slug",
    component: _156f6310,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___en"
  }, {
    path: "/bot-link/:link?",
    component: _c2894306,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link___en"
  }, {
    path: "/confirm/:token?",
    component: _7513ffdc,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token___en"
  }, {
    path: "/tr/*",
    component: _21977e08,
    pathToRegexpOptions: {"strict":true},
    name: "all___tr"
  }, {
    path: "/cn/*",
    component: _21977e08,
    pathToRegexpOptions: {"strict":true},
    name: "all___cn"
  }, {
    path: "/ru/*",
    component: _21977e08,
    pathToRegexpOptions: {"strict":true},
    name: "all___ru"
  }, {
    path: "/catalog/*",
    component: _43e3da86,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-all___en"
  }, {
    path: "/countries/*",
    component: _3f5867a1,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all___en"
  }, {
    path: "/*",
    component: _21977e08,
    pathToRegexpOptions: {"strict":true},
    name: "all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
